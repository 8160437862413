import {
  AreaChart,
  type AreaChartProps,
  type RangeSelectorConfigItem,
  TimeAmounts,
} from '@bts-web/design-system/component/chart';
import {
  areaChartStyles,
  tabBarMiniStyles,
  timelineBarStyles,
  type AreaChartStylesVariantProps,
  type TabBarMiniStylesVariantProps,
} from 'bts-style-engine/recipes';

export type ThemedAreaChartProps = AreaChartStylesVariantProps &
  AreaChartProps['external'] & {
    color?: TabBarMiniStylesVariantProps['color'];
  };

const ThemedAreaChart = ({
  visual,
  rangeSelectorsConfig,
  size,
  color,
  ...chartProps
}: ThemedAreaChartProps) => {
  return (
    <AreaChart
      {...chartProps}
      size={size}
      className={areaChartStyles({ visual, size })}
      rangeSelectorClassName={tabBarMiniStyles({
        color,
        visual: { base: 'underlineSelected', lg: 'chips' },
      })}
      timelineClassName={timelineBarStyles({ visual })}
      rangeSelectorsConfig={rangeSelectorsConfig}
    />
  );
};

export {
  ThemedAreaChart as AreaChart,
  AreaChartStylesVariantProps,
  RangeSelectorConfigItem,
  TimeAmounts,
};
