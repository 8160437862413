'use client';

import { useRef, useEffect } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import {
  PortfolioContentTranslationsKeys,
  PortfolioLoadingSkeleton,
} from '@bts-web/core-features/portfolio';
import { AreaChart } from '../../../base-components/charts/Area/AreaChart';
import { css } from '@bts-web/utils-style-engine';

export function NavigationEvents({
  translations,
}: {
  translations: PortfolioContentTranslationsKeys;
}) {
  const router = useRouter();

  const previousPathNameRef = useRef<string>();

  const currentPathname = usePathname();

  const shouldRedirect = shouldRedirectToPortfolio(
    previousPathNameRef.current,
    currentPathname,
  );

  useEffect(() => {
    if (shouldRedirect) {
      router.push('/assets/portfolio');
    }

    previousPathNameRef.current = currentPathname;
  }, [currentPathname, router, shouldRedirect]);

  if (!shouldRedirect) {
    return null;
  }

  return (
    shouldRedirect && (
      <div className={overlayStyle}>
        <PortfolioLoadingSkeleton
          AreaChartComponent={AreaChart}
          translations={translations}
        />
        ;
      </div>
    )
  );
}

function shouldRedirectToPortfolio(
  previousPathName: string | undefined,
  currentPathname: string,
) {
  const tradeFinishPartialRoutes = [
    'buy/success',
    'buy/failure',
    'buy/pending',
    'sell/success',
    'sell/failure',
    'sell/pending',
  ];

  const tradeAcceptPartialRoutes = ['buy/accept', 'sell/accept'];

  if (previousPathName) {
    const isComingFromTradeFinishScreen = tradeFinishPartialRoutes.some((p) =>
      previousPathName.includes(p),
    );

    const isGoingToTradeAcceptScreen = tradeAcceptPartialRoutes.some((p) =>
      currentPathname.includes(p),
    );

    if (isComingFromTradeFinishScreen && isGoingToTradeAcceptScreen) {
      return true;
    }
  }

  return false;
}

const overlayStyle = css({
  position: 'fixed',
  zIndex: 3,
  backgroundColor: 'screen_background.primary',
  width: '100%',
});
