// Necessary if using App Router to ensure this file runs on the client
'use client';

import { datadogRum } from '@datadog/browser-rum';

// see .gitlab-ci/apps/lbbw.yml for Datadog variables
const dataDogEnv = process.env.NEXT_PUBLIC_DATADOG_ENV;

const dataDogRumService = process.env.NEXT_PUBLIC_DATADOG_RUM_SERVICE;

const dataDogSite = process.env.NEXT_PUBLIC_DATADOG_SITE;

const appVersion = process.env.NEXT_PUBLIC_APP_VERSION;

const apiUrl = process.env.NEXT_PUBLIC_GQL_API_ENDPOINT_URL;

// does not run RUM on local
if (dataDogEnv && apiUrl && appVersion) {
  datadogRum.init({
    applicationId: '69a099da-a5ab-45b5-a633-8faa3be2d1a2',
    clientToken: 'pub5a20334675db4bf88a2ee2f3811487b4',
    site: dataDogSite,
    service: dataDogRumService,
    env: dataDogEnv,
    version: appVersion,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    storeContextsAcrossPages: true,
    allowedTracingUrls: [{ match: apiUrl, propagatorTypes: ['tracecontext'] }],
  });
}

const DatadogRumInit = () => {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
};

export { DatadogRumInit };
